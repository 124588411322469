





























































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { api_user } from "@/api";
import { numFormat, dateToLast, dateToKakao } from "@/util/string"
import { Encryption } from "@/util/encryption";
@Component
export default class extends More(api_user.get_course) {
    numFormat = numFormat
    dateToLast = dateToLast
    dateToKakao = dateToKakao
  get is_mobile() {
    return OtherModule.is_mobile;
  }

    to_info(status:number, id:number){
        if(status === 2){
            this.$router.push(`/user/course/${Encryption.base_enc(id)}`)
        }
    }
}
